<template>
    <div>
        <v-row justify="end">
            <v-col cols="12" sm="1" md="1" lg="1" class="mr-0 pr-1" style="display: flex; justify-content: flex-end;">
                <v-menu offset-y>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn  v-bind="attrs" v-on="on" icon><v-icon>view_week</v-icon></v-btn>                            
                    </template>
                    <v-list style="overflow: hidden;">
                        <v-list-item v-for="(item, index) in headers" :key="index" >
                            <v-list-item-action>
                                <v-checkbox
                                v-model="item.show"
                                @change="toggleColumn(item)"
                                :label="item.text"
                                hide-details
                                ></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                        <v-row class="pt-5" style="overflow-y: hidden">
                            <v-col cols="12" md="12" sm="12" lg="12" class="d-flex justify-center">
                            <v-btn @click="saveCustom" outlined color="primary">SAVE</v-btn>
                        </v-col>
                        </v-row>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" class="ml-0 pl-0">
                <el-input
                  
                  id="search"
                  suffix-icon="el-icon-search"
                  prefix-icon="Filter"
                  v-model="search"
                  clearable
                  placeholder="Search"
                >              
                    <template slot="prepend">
                        <el-button >
                          <i class="el-icon-plus"></i>
                        </el-button>
                    </template>
                    <template slot="append">
                      <el-button @click="filterCard = true">
                        <v-badge :content="filterCount" :value="filtersCount > 0 && filterOn" color="primary" offset-y="10">
                            <v-icon>filter_alt</v-icon>
                        </v-badge>
                      </el-button>
                    </template> 
                </el-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" :sm="overviewCard ? 9 : 12" :md="overviewCard ? 9 : 12" :lg="overviewCard ? 9 : 12">
                <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="filteredBookings"
                :loading="loadingTable"
                :search="search"
                height="74vh"
                :key="tableKey"

                >
                <template v-slot:[`item.loadPoint1`]="{ item }">
                    <v-chip small color="primary" v-if="item.activityPoints" outlined>
                        {{ item.activityPoints[0] ? item.activityPoints[0].name : item.activityPoints.name }}
                    </v-chip>

                </template>
                <template  v-slot:[`item.loadPoint2`]="{ item }">
                    <v-chip v-if="item.activityPoints && item.activityPoints[1]" small outlined color="primary" style="margin: 0.2vh 0 0 0">
                        {{item.activityPoints[1].name}}
                    </v-chip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-menu  offset-y style="min-width: 8vw;" rounded >
                        <template v-slot:activator="{on, attrs}">
                            <v-chip small :color="matchColor(item.status)">
                                <v-icon class="mr-2" color="yellow" small v-if="item.status == 'ISSUE'">warning</v-icon>{{item.status}} <span v-if="item.statusLocation && (item.status == 'AT' || item.status == 'EN ROUTE TO')" style="margin-left: 5px">{{item.statusLocation }}</span> <v-btn v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn>
                            </v-chip>
                        </template>
                        <v-list dense color="greyBase" rounded>
                            <v-menu offset-y rounded>
                                <template v-slot:activator="{on, attrs}">
                                    <v-list-item
                                    @click="changeStatus(item.id, status)"
                                    v-for="status in statuses"
                                    :key="status.id"
                                    >
                                    <div class="colorBlock" :style="{'background-color': status.color }"></div> <small style="margin-left: 10px;"><b>{{ status.state }}</b></small>
                                    <v-list-item-action v-if="status.state == 'EN ROUTE TO'" style="position: absolute; right: 0;" ><v-btn @click="statusDropDown('EN ROUTE TO')" v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn></v-list-item-action>
                                    <v-list-item-action v-if="status.state == 'AT' " style="position: absolute; right: 0;" ><v-btn @click="statusDropDown('AT')" v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn></v-list-item-action>
                                </v-list-item>
                            </template>
                            <v-list dense color="greyBase" rounded>
                                <v-list-item
                                v-for="(location, index) in statusLocations"
                                :key="index"
                                @click="changeStatus(item.id, status ? status : 'null', location)"
                                >
                                <small><b>{{ location.name }}</b></small>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        </v-list>
                    </v-menu>
                </template>
                </v-data-table>
            </v-col>
            <v-col cols="12">
            <el-row justify="center" type="flex">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :page-size.sync="params.limit" layout="prev, pager, next" :total="bookingsTotal">
                </el-pagination>
            </el-row>
        </v-col>
        </v-row>
        <v-dialog v-model="filterCard" width="30vw">
            <v-card>
                <v-toolbar>
                    <v-card-title>Filter Bookings</v-card-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top v-if="!filterOn">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="!filterOn" @click="filterOn = true" v-on="on"  icon><v-icon>filter_alt</v-icon></v-btn>
                        </template>
                        <span>Enable Filters</span>
                    </v-tooltip>
                    <v-tooltip top v-if="filterOn">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="filterOn"  @click="filterOn = false" v-on="on" icon><v-icon>filter_alt_off</v-icon></v-btn>
                        </template>
                        <span>Disable Filters</span>
                    </v-tooltip>
                        <v-btn  icon @click="filterCard = false"><v-icon color="primary">close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col>
                            <v-autocomplete multiple :items="shippers"  label="Shipper" persistent-placeholder v-model="shipperFilterList"></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete multiple :items="vessels" label="Vessel" persistent-placeholder v-model="vesselFilterList"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-autocomplete multiple :items="pols" label="Port of Loading" persistent-placeholder v-model="polFilterList"></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete multiple :items="pods" label="Port of Discharge" persistent-placeholder v-model="podFilterList"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-subtitle class="ml-0 pl-0">Status</v-card-subtitle>
                            <v-chip-group :key="chipKey"  column multiple>
                                <v-chip  
                                filter 
                                @click="statusFilters.at = !statusFilters.at" 
                                :color="statusFilters.at ? 'success' : 'grey'" 
                                :outlined="statusFilters.at ? false : true"
                                >
                                AT
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.assigned = !statusFilters.assigned" 
                                :color="statusFilters.assigned ? 'success' : 'grey'" 
                                :outlined="statusFilters.assigned ? false : true"
                                >
                                ASSIGNED
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.completed = !statusFilters.completed" 
                                :color="statusFilters.completed ? 'success' : 'grey'" 
                                :outlined="statusFilters.completed ? false : true"
                                >
                                COMPLETED
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters['done Loading'] = !statusFilters['done Loading']" 
                                :color="statusFilters['done Loading'] ? 'success' : 'grey'" 
                                :outlined="statusFilters['done Loading'] ? false : true"
                                >
                                DONE LOADING
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters['en Route To'] = !statusFilters['en Route To']" 
                                :color="statusFilters['en Route To'] ? 'success' : 'grey'" 
                                :outlined="statusFilters['en Route To'] ? false : true"
                                >
                                EN ROUTE TO
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.stopped = !statusFilters.stopped" 
                                :color="statusFilters.stopped ? 'success' : 'grey'" 
                                :outlined="statusFilters.stopped ? false : true"
                                >
                                STOPPED
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.cancelled = !statusFilters.cancelled" 
                                :color="statusFilters.cancelled ? 'success' : 'grey'" 
                                :outlined="statusFilters.cancelled ? false : true"
                                >
                                CANCELLED
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.issue = !statusFilters.issue" 
                                :color="statusFilters.issue ? 'success' : 'grey'" 
                                :outlined="statusFilters.issue ? false : true"
                                >
                                ISSUE
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters.pending = !statusFilters.pending" 
                                :color="statusFilters.pending ? 'success' : 'grey'" 
                                :outlined="statusFilters.pending ? false : true"
                                >
                                PENDING
                            </v-chip>
                                <v-chip  
                                filter 
                                @click="statusFilters['to Be Allocated'] = !statusFilters['to Be Allocated']" 
                                :color="statusFilters['to Be Allocated'] ? 'success' : 'grey'" 
                                :outlined="statusFilters['to Be Allocated'] ? false : true"
                                >
                                TO BE ALLOCATED
                            </v-chip>
                            </v-chip-group>
                    </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>


export default {
data: () => ({
    search: '',
    overviewCard: false,
    loadingTable: false,
    showStatusChange: false,
    loadingSaveCustom: false,
    filterCard: false,
    statusSpecific: null,
    tableKey: 0,
    filterCount: 0,
    chipKey: 0,
    bookingsTotal: 0,
    page: 1,
    filterOn: true,
    bookings: [],
    bookingsStore: [],
    statuses: [
        {id:4,  state:'AT',              color:'#006bc3', location: null},
        {id:3,  state:'ASSIGNED',        color:'#0094db', location: null},
        {id:8,  state:'COMPLETED',       color:'#008a61', location: null},
        {id:6,  state:'DONE LOADING',    color:'#2cc194', location: null},
        {id:5,  state:'EN ROUTE TO',     color:'#ccb50a', location: null},
        {id:7,  state:'STOPPED',         color:'#e55000', location: null},
        {id:9,  state:'CANCELLED',       color:'#ff6666', location: null},
        {id:10, state:'ISSUE',           color:'#7f0000', location: null},
        {id:2,  state:'PENDING',         color:'#657583', location: null},
        {id:1,  state:'TO BE ALLOCATED', color:'#b4bbc1', location: null},
    ],
    statusLocations: [
        {name:'Loading Point 1', value: 'LP1'},
        {name:'Loading Point 2' , value: 'LP2'},
        {name:'Empty Pickup Point' , value: 'EMPTY'},
        {name:'Port of Loading' , value: 'POL'},
        {name:'Depot' , value: 'DEPOT'},
        {name:'Yard' , value: 'YARD'},
        {name:'Fuel Station' , value: 'FUEL STATION'},
    ],
    statusFilters: {
        at: false,
        assigned: false,
        completed: false,
        'done Loading': false,
        'en Route To': false,
        stopped: false,
        cancelled: false,
        issue: false,
        pending: false,
        'to Be Allocated': false,
    },
    shippers: [],
    shipperFilterList: [],
    vessels: [],
    vesselFilterList: [],
    pols: [],
    polFilterList: [],
    pods: [],
    podFilterList: [],
    headers: [
        {
            text: 'Shipper',
            value: 'ppecbBooking.shipper',
            align: 'left',
            show: true,
            alias: 'shipper',
        },
        {
            text: 'CTO No.',
            value: 'loadingInstructionNumber',
            align: 'left',
            show: true,
            alias: 'loadingInstructionNumber',
        },
        {
            text: 'Vessel',
            value: 'ppecbBooking.vesselName',
            align: 'left',
            show: true,
            alias: 'vesselName',
        },
        {
            text: 'POD',
            value: 'finalPortOfDischarge',
            align: 'center',
            show: true,
            alias: 'finalPortOfDischarge',
        },
        {
            text: 'POL',
            value: 'ppecbBooking.loadPort',
            align: 'center',
            show: true,
            alias: 'loadPort',
        },
        {
            text: 'Container No.',
            value: 'containerAndSealNumber',
            align: 'left',
            show: true,
            alias: 'containerAndSealNumber',
        },
        {
            text: 'Customer Ref',
            value: 'customerReference',
            align: 'left',
            show: true,
            alias: 'customerReference',
        },
        {
            text: 'Shipping Line Ref',
            value: 'shippingLineReference',
            align: 'left',
            show: true,
            alias: 'shippingLineReference',
        },
        {
            text: 'Empty Loading',
            value: 'emptyLoadingPoint',
            align: 'left',
            show: true,
            alias: 'emptyLoadingPoint',
        },
        {
            text: 'Loading Point 1',
            value: 'loadPoint1',
            align: 'left',
            show: true,
            alias: 'loadPoint1',
        },
        {
            text: 'Loading Point 2',
            value: 'loadPoint2',
            align: 'left',
            show: true,
            alias: 'loadPoint2',
        },
        {
            text: 'Booking Date',
            value: 'containerBookingDate',
            align: 'left',
            show: true,
            alias: 'containerBookingDate',
        },
        {
            text: 'Booking Time',
            value: 'cotainerBookingTime',
            align: 'left',
            show: true,
            alias: 'cotainerBookingTime',
        },
        {
            text: 'Status',
            value: 'status',
            align: 'left',
            show: true,
            alias: 'status',
        },
    ],
    colors: [
        {hex: '#04a8f3', name: 'Light Blue'},
        {hex: '#0230e7', name: 'Blue'},
        {hex: '#7b4cfe', name: 'Purple'},
        {hex: '#2dcc6e', name: 'Light Green'},
        {hex: '#145930', name: 'Green'},
        {hex: '#e55000', name: 'Orange'},
        {hex: '#ccb50a', name: 'Yellow'},
        {hex: '#e40000', name: 'Red'},
        {hex: '#7f0000', name: 'Maroon'},
        {hex: '#f800e9', name: 'Pink'},
        {hex: '#b4bbc1', name: 'Gray'},
        {hex: '#657583', name: 'Dark Gray'},
    ],
    params: {
            limit: 20,
            offset: 0,
            search: null,
            filter: { isActive: [true] }
        },
}),

async created() {
    this.getBookings()
    let headerView = await this.$API.getReportCustom(this.$store.state.user.id)
    this.updateHeaders(headerView)

},
computed: {
    filteredBookings(){
      let result = this.bookings
      let filter = {}
      // this.params.filter = {}
      filter.statuses = this.statusFilters
      filter.shippers = this.shipperFilterList
      filter.vessels = this.vesselFilterList
      filter.pols = this.polFilterList
      filter.pods = this.podFilterList
      filter = JSON.parse(JSON.stringify(filter))
    if(this.filterOn) {
      if(filter.shippers && filter.shippers.length > 0){
        result = result.filter(x=> filter.shippers.includes(x.ppecbBooking.shipper))
      }
      if(filter.vessels && filter.vessels.length > 0){
        result = result.filter(x=> filter.vessels.includes(x.ppecbBooking.vesselName))
      }
      if(filter.pols && filter.pols.length > 0){
        result = result.filter(x=> filter.pols.includes(x.ppecbBooking.loadPort))
      }
      if(filter.pods && filter.pods.length > 0){
        result = result.filter(x=> filter.pods.includes(x.finalPortOfDischarge))
      }
      if (Object.values(filter.statuses).some((x) => x)) {
      result = result.filter((x) =>
        Object.keys(filter.statuses).some(
          (key) => filter.statuses[key] && x.status === key.toUpperCase()
        )
      );
    }
    }
      return result
    },

    filtersCount() {
      let filter = {}
      let filterCount = 0
      // this.params.filter = {}
      filter.statuses = this.statusFilters
      filter.shippers = this.shipperFilterList
      filter.vessels = this.vesselFilterList
      filter.pols = this.polFilterList
      filter.pods = this.podFilterList
      filter = JSON.parse(JSON.stringify(filter))
    if(this.filterOn) {
      if(filter.shippers && filter.shippers.length > 0){
       filterCount += filter.shippers.length
      }
      if(filter.vessels && filter.vessels.length > 0){
       filterCount += filter.vessels.length
      }
      if(filter.pols && filter.pols.length > 0){
       filterCount += filter.pols.length
      }
      if(filter.pods && filter.pods.length > 0){
       filterCount += filter.pods.length
      }
      if (Object.values(filter.statuses).some((x) => x)) {
     filterCount += Object.values(filter.statuses).filter((x) => x).length;}
    }
    return filterCount
    },
},
watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getBookings()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                await this.getBookings()

            }
        },
},
methods: {
    matchColor(itemStatus) {
        let hex = this.statuses.find(status => status.state === itemStatus).color
        return hex
    },

    async getBookings() {
        this.loadingTable = true
        let bookingData = await this.$API.getTransportBookingItems({params: this.params})
        this.bookings = bookingData.data
        this.bookingsTotal = bookingData.total
        this.loadingTable = false
        this.sortFilterLists()
    },

    async changeStatus(id, status, location) {
        console.log(id, status, location)
        if(location == undefined) {
            this.bookings.find(booking => booking.id === id).status = status.state
            this.showStatusChange = false
            let result = await this.$API.updateBookingStatus({
            id: id,
            status: status.state,
            statusLocation: null,
        })
        } else {
            this.bookings.find(booking => booking.id === id).status = this.statusSpecific.state
            this.bookings.find(booking => booking.id === id).statusLocation = location.value
            this.showStatusChange = false
            let result = await this.$API.updateBookingStatus({
                id: id,
                status: this.statusSpecific.state,                                                                                                                                                                                                              
                statusLocation: location ? location.value : null,
            })
            if(result) {
                this.$message.success('Status Updated');
            }
        }
    },

    statusDropDown(itemStatus) {
        this.statusSpecific = this.statuses.find(status => status.state === itemStatus)
    },

    toggleColumn(item) {
        console.log(item)
        if(item.show === true) {
            this.headers.find(header => header.value === item.value).align = 'left'
            this.tableKey++
        } else {
            this.headers.find(header => header.value === item.value).align = ' d-none'
            this.tableKey++
        }
    },

    updateHeaders(obj) {
    if (obj) {
        Object.entries(obj).forEach(([key, value]) => {
          this.headers.forEach((header) => {
            if (header.alias === key) {
              header.show = value;
              if(value === true) {
                header.align = 'left'
              } else {
                header.align = ' d-none'
              }
            }
          });
        });
        return this.headers;
    } else {
      return this.headers;
    }
    },

    saveCustom() {
        this.loadingSaveCustom = true
        let obj = {}
        obj.userId = this.$store.state.user.id
        this.headers.forEach((header) => {
            obj[header.alias] = header.show
        })
        this.$API.saveReportCustom(obj)
        this.loadingSaveCustom = false
    },

    sortFilterLists() {
        this.shippers = this.bookings.map(booking => booking.ppecbBooking.shipper).filter((value, index, self) => self.indexOf(value) === index && value !== null)
        this.vessels = this.bookings.map(booking => booking.ppecbBooking.vesselName).filter((value, index, self) => self.indexOf(value) === index && value !== null)
        this.pods = this.bookings.map(booking => booking.finalPortOfDischarge).filter((value, index, self) => self.indexOf(value) === index && value !== null)
        this.pols = this.bookings.map(booking => booking.ppecbBooking.loadPort).filter((value, index, self) => self.indexOf(value) === index && value !== null)
    }


}
}
</script>

<style scoped>

.colorBlock {
    height: 12px; 
    width: 12px; 
    border-radius: 2.5px;
}

.selectedChip {
    color: green;
}

</style>